<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="white">
        <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Buscar Filial"></v-text-field>
        <v-spacer></v-spacer>
          <v-btn color="primary" @click="novaFilial()" data-cy="novo" v-if="$store.state.usuario.nivel_acesso === 'ADMINISTRADOR'">Nova Filial</v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-data-table :footer-props="footerOptions" :headers="headers" :items="filiais" :search="busca" :loading="carregando">
        <template v-slot:item="props">
          <tr>
            <td class="text-no-wrap">{{ props.item.cnpj | formatarCnpj }}</td>
            <td>{{ props.item.razao }}</td>
            <td><v-chip chip color="primary">{{ constantes.TIPOS_MODO.find(modo => modo.value === props.item.plano).text }}</v-chip></td>
            <td>{{ props.item.ambiente | codigoParaAmbiente }}</td>
            <td v-if="props.item._id === $store.state.empresaAtual._id"><v-icon color="green lighten-1">gps_fixed</v-icon></td>
            <td v-else><v-icon @click="alterarEmpresa(props.item)">gps_not_fixed</v-icon></td>
            <td class="text-center">
              <v-menu top right transition="slide-x-transition" @click.native.stop="" v-if="props.item._id !== $store.state.empresaAtual._id">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">more_vert</v-icon>
                </template>
                <v-list dense>
                  <v-list-item :key="props.item._id" @click="editarFilial(props.item)" data-cy="editar">
                    <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'CNPJ', value: 'cnpj', sortable: false },
          { text: 'Razão Social', value: 'razao', sortable: false },
          { text: 'Modo Utilização', value: 'plano', sortable: false },
          { text: 'Ambiente', value: 'ambiente', sortable: false },
          { text: 'Atual', value: 'atual', sortable: false },
          { text: 'Ações', value: 'acoes', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    computed: {
      ...mapState('filial', {
        filiais: 'filiais',
        carregando: 'carregando',
        total: 'totalFiliais'
      }),
      ehEmpresaPadrao(empresa){
        return this.$store.state.empresaAtual._id === empresa._id;
      }
    },

    methods: {

      novaFilial(){
        this.$store.commit('filial/nova');
        this.$router.push('/filiais/nova');
      },

      editarFilial(filial){
        this.$store.commit('filial/editar', filial);
        this.$router.push('/filiais/editar');
      },

      carregarFiliais(){
        this.$store.dispatch('filial/carregar', this.options);
      },

      alterarEmpresa(empresa){
        this.$store.dispatch('alterarEmpresa', empresa);
      },

      remover(empresa){
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('filial/remover', empresa);
          }
        });
      },

      definirComoPadrao(empresa){
        this.$root.$confirmacao.open('Empresa Padrão', 'Tem certeza que deseja definir ' + formatador.formatarCnpj(empresa.cnpj) + ' como empresa padrão?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('definirEmpresaPadrao', empresa._id);
          }
        });
      }
    },

    created(){
      //chamada a action através do dispatch
      this.carregarFiliais();
    }
  }
</script>