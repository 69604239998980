<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" max-width="300px" prepend-inner-icon="search" :label="constantes.BOTAO_FILTRAR_USUARIO"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="novoUsuario">{{constantes.BOTAO_NOVO_USUARIO}}</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :footer-props="footerOptions" :headers="headers" :items="usuarios" :search="busca" :loading="carregando">
          <template v-slot:item="{ item: usuario }">
            <tr>  
              <td>{{ usuario.nome }}</td>
              <td>{{ usuario.email }}</td>
              <td class="text-center">
                <v-btn  icon @click="editarUsuario(usuario)">
                  <v-icon>edit</v-icon>
                </v-btn>
                <!-- <v-menu top right transition="slide-x-transition" @click.native.stop="" v-if="usuario.email !== $store.state.usuario.email">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="usuario._id + 10" @click="editarUsuario(usuario)">
                      <v-list-item-icon>
                        <v-icon>edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'Nome', value: 'nome' },
          { text: 'E-mail', value: 'email' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    computed: {
      ...mapState('usuario_sistema', {
        usuarios: 'usuarios',
        carregando: 'carregando'
      })
    },

    methods: {
      ...mapActions('usuario_sistema', {
        carregarUsuarios: 'carregar'
      }),

      limparBusca(){
        this.carregarMovimentos();
      },

      editarUsuario(usuario){
        this.$store.commit('usuario_sistema/editar', usuario);
        this.$router.push({ name: 'formularioUsuarioEditar' });
      },

      novoUsuario(){
        this.$store.commit('usuario_sistema/novo');
        this.$router.push({ name: 'formularioUsuarioNovo' });
      },

      remover(usuario){
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('usuario_sistema/remover', usuario);
          }
        });
      }
    },

    created(){
      //chamada a action através do dispatch
      this.carregarUsuarios();
    }
  }
</script>